import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './IOActiveOffersPage.module.scss';
import { useIntl } from 'react-intl';
import { logFsEvent, buildEventName } from '../../utils/fullstory';
import { fullstoryConstants } from '../../constants/fullstory';
import { Button } from '@kmx/legos-react-button';
import { useNavigation, OfferContext, useCheckIn, useSpinner } from '../../context';
import IOSteps from '../../flows/steps/IOSteps';
import ActiveOffersTitle from '../../components/ActiveOffersTitle/ActiveOffersTitle';
import OfferInfoCard from '../../components/OfferInfoCard/OfferInfoCard';
import { formatCurrencyWithoutCents } from '../../utils/index';
import { findAppointment } from '../../api/sharedApiCalls';
import { storeTypes } from '../../constants/storeTypes';
import { trackSelectedOffer } from '../../utils/adobe/analytics';
import { useEnterToMoveOn } from '../../hooks/useEnterToMoveOn';
import { useErrorHandling } from '../../context/errorHandling';
import useInitVisuals from '../../hooks/useInitVisuals';

export default function IOActiveOffersPage({ offers }) {
    const { formatMessage } = useIntl();
    const [, showSpinner] = useSpinner();
    const { setError } = useErrorHandling();

    const { setStep } = useNavigation();
    const { setOffer } = useContext(OfferContext);
    const [checkInInfo] = useCheckIn();
    const { storeFormat } = checkInInfo;

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [selectedOffer, setSelectedOffer] = useState({});
    const [finalSelectedOfferObject, setFinalSelectedOfferObject] = useState({});

    const mapOffersDataForOfferInfoCard = (offers) => {
        return offers?.map((offer) => {
            return {
                offer: offer,
                title: `${offer?.code} - valid thru ${offer?.expiresDisplayDate}`,
                body: `${offer?.year} ${offer?.make} ${offer?.model}`,
                offerPrice: `${formatCurrencyWithoutCents(offer?.valuation)}`,
                selected: false,
                code: offer?.code,
            };
        });
    };

    const clearAllOffersToUnselected = (offers) => {
        return offers?.map((offer) => {
            return { ...offer, selected: false };
        });
    };

    const findSelectedOffer = (offers, code) => {
        return offers.filter((offer) => offer?.code === code);
    };

    const offerInfoCardData = mapOffersDataForOfferInfoCard(offers);

    const handleContinueButtonClick = () => {
        showSpinner(true);
        const code = finalSelectedOfferObject?.code;

        findAppointment(code)
            .then((customerInfo) => {
                if (checkInInfo.phoneNumber) {
                    customerInfo.phoneNumber = checkInInfo.phoneNumber;
                }
                Object.assign(finalSelectedOfferObject, customerInfo ?? { code: code });
                setOffer((prevState) => ({ ...prevState, ...finalSelectedOfferObject }));

                // If the offer is an appraisal checkout, redirect to the express completed page,
                // otherwise, redirect to confirm single offer page
                const isSelectedOfferAppraisalCheckout = offers?.filter(
                    (offer) => offer?.code === code,
                )[0].isAppraisalCheckout;

                if (isSelectedOfferAppraisalCheckout === true) {
                    // redirect to XF or Traditional store completed page, based on current store type
                    if (storeFormat === storeTypes.XF) {
                        setStep(IOSteps.ExpressCheckInXFStoreCompletePage);
                    } else if (storeFormat === storeTypes.Traditional || storeFormat === storeTypes.CBC) {
                        setStep(IOSteps.ExpressCheckInTraditionalStoreCompletePage);
                    } else {
                        setError.general('Invalid store format', {
                            additionalInfo: { storeFormat },
                        });
                    }
                } else {
                    setStep(IOSteps.ConfirmSingleOfferPage);
                }
            })
            .catch((error) => setError.general('Failed finding appointment ', { error }));

        logFsEvent(
            buildEventName(
                fullstoryConstants.activeOffersPage.name,
                fullstoryConstants.activeOffersPage.events.continueButton,
            ),
        );
    };

    useInitVisuals();

    useEffect(() => {
        if (selectedOffer?.code?.length > 0) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }
        clearAllOffersToUnselected(offers);
    }, []);

    useEffect(() => {
        const selectedOfferObject = findSelectedOffer(offers, selectedOffer?.code);

        if (selectedOffer?.code?.length > 0) {
            setIsButtonDisabled(false);
        } else {
            setIsButtonDisabled(true);
        }

        if (selectedOfferObject && selectedOfferObject?.length === 1) {
            setFinalSelectedOfferObject(selectedOfferObject[0]);
            trackSelectedOffer(selectedOfferObject[0]);
        }
    }, [selectedOffer.code]);

    const renderOffers = (offers) =>
        offers?.map((offer) => {
            if (offer?.code === selectedOffer?.code) {
                return (
                    <OfferInfoCard
                        key={`key-${offer?.code}`}
                        offer={offer}
                        title={offer?.title}
                        body={offer?.body}
                        offerPrice={offer?.offerPrice}
                        setSelectedOffer={setSelectedOffer}
                        offerInfoCardIsSelected={true}
                    />
                );
            } else {
                return (
                    <OfferInfoCard
                        key={`key-${offer?.code}`}
                        offer={offer}
                        title={offer?.title}
                        body={offer?.body}
                        offerPrice={offer?.offerPrice}
                        setSelectedOffer={setSelectedOffer}
                        offerInfoCardIsSelected={false}
                    />
                );
            }
        });

    useEnterToMoveOn(handleContinueButtonClick, null, () => !isButtonDisabled);

    return (
        <div className={styles.container}>
            <div>
                <ActiveOffersTitle
                    title={formatMessage({ id: 'activeOffersPage.title' })}
                    subtitle={formatMessage({ id: 'activeOffersPage.subtitle' })}
                />
            </div>
            <div className={styles.scrollable}>{renderOffers(offerInfoCardData)}</div>
            <div className={styles.buttonContainer}>
                <Button
                    onClick={() => handleContinueButtonClick()}
                    className={styles.primary}
                    level="primary"
                    disabled={isButtonDisabled}
                >
                    {formatMessage({ id: 'activeOffersPage.buttonTitle' })}
                </Button>
            </div>
        </div>
    );
}

IOActiveOffersPage.propTypes = {
    offers: PropTypes.array.isRequired,
};
